import { DesignData, SpreadGroundImage, SpreadGroup } from 'editor/src/store/design/types';

export type ProductUnAvailability = 'region_availability' | 'dispatch_sla';

export interface Product {
  title: string;
  categoryTitle: string;
  description: string;
  productVariations: ProductVariation[];
  productControls: VariationProductControl[];
  externalProductControls: ExternalProductControl[];
  controlsUIOrder: string[];
  previewUrls: string[];
  outOfStock: { [productUid: string]: true };
  pages?: PageOptions;
  groupBy: string[];
  unAvailableVariantsGroupBy?: string;
  unavailableProducts: { [productUid: string]: ProductUnAvailability[] | true };
  confirmOnControlChange?: {
    controlKey: string;
    values: { value: string; message: string; messageTitle: string }[];
  }[];
}

export interface ECommerceProduct {
  selectedVariantId: string;
  title: string;
  variants: ECommerceVariant[];
  options: ECommerceVariantOption[];
  quantity: number;
}

export interface ECommerceVariant {
  id: string;
  price: string;
  compareAtPrice?: string;
  optionValues: string[];
}

export interface ECommerceVariantOption {
  name: string;
  hidden?: true;
  values: string[];
}

export interface PageOptions {
  max: number;
  min: number;
  step: number;
  name: string;
}

export type ProductVariation = {
  productUid: string;
  [attribute: string]: string;
};

export type VariationProductControl = ProductControlMulti | ProductControlSingle;
export type ExternalProductControl = ProductControlSize;

export function isVariationProductControl(
  control: VariationProductControl | ExternalProductControl,
): control is VariationProductControl {
  return control.type !== 'external';
}

export function isExternalProductControl(
  control: VariationProductControl | ExternalProductControl,
): control is ExternalProductControl {
  return control.type === 'external';
}

export interface ProductControlMulti {
  type: 'multi';
  uiType: ControlUIType;
  dependsOnSingleControls: boolean;
  key: string;
  title: string;
  options: ProductControlOption[];
  allowSelectAll?: boolean;
  collapsible?: boolean;
  behavior?: 'normal' | 'replace';
}

export type ValueDependency = { key: string; value: string };

export type Size = { width: number; height: number };

export type ProductSizeOption = {
  value: string;
  width: number;
  height: number;
};
export type DesignOption = { value: string; title: string };
export type ExternalProductControlOption = ProductSizeOption;
export type DefaultSizeCalculationParameterRecord = {
  dimensionIncrement: number;
  dimensionMax: number;
  referenceDimension: 'width' | 'height';
  otherDimensionValues: number[];
};

export type ProductSizeUnit = 'cm' | 'inch';

export type DefaultSizeCalculationParameters = {
  [key in ProductSizeUnit]: DefaultSizeCalculationParameterRecord;
};

export interface DesignOptionControl {
  type: 'external';
  subtype: string;
  key: 'design-option';
  title: string;
  options: { value: string; title: string }[];
}

export interface ProductControlSize {
  type: 'external';
  key: 'product-size';
  title: string;
  options: ProductSizeOption[];
  editableDimension?: 'width' | 'height';
  defaultSizeCalculationParameters?: DefaultSizeCalculationParameters;
}
export const ProductSizeControlKey = 'product-size';

export interface ProductControlSingle {
  type: 'single';
  uiType: ControlUIType;
  key: string;
  title: string;
  options: ProductControlOption[];
  behavior?: 'normal' | 'replace';
}

export type ControlUIType = 'checkbox' | 'button' | 'color' | 'size' | 'dropdown' | 'material';

export type ProductControlOption = ProductControlOptionDefault | ProductControlOptionSize | ProductControlOptionColor;

export interface ProductControlOptionDefault {
  title: string;
  value: string;
  disabledTitle?: string;
}

export interface ProductControlOptionSize extends ProductControlOptionDefault {
  imperial: boolean;
}

export interface ProductControlOptionColor extends ProductControlOptionDefault {
  colorHex: string;
  texture?: string;
}

export interface AdditionalInfo {
  dimensions: { width: number; height: number } | undefined;
  pageCount: number | undefined;
  relatedDimensions?: { width: number; height: number };
}

export interface VariantInfo extends AdditionalInfo {
  variation: ProductVariation;
}

export interface Variant extends VariantInfo {
  designData: DesignData | undefined;
}

export interface VariationGroup {
  key: string;
  title: string;
  linked: boolean;
  linkingDisabled: boolean;
  outOfStock: boolean;
  variationsInfo: Variant[];
  designOptions?: { optionKey: string; designOptionControlSubKey: string }[];
}

export type ExistingVariant = {
  productUid: string;
  designData: DesignData | undefined;
  linked: boolean;
  designOptions?: { optionKey: string; designOptionControlSubKey: string }[];
};

export type MultiOption = {
  value: string;
  dependedOptions: Array<{ key: string; value: string }>;
};
export type MultiOptions = { [key: string]: MultiOption[] };
export type ExternalOptions = { [key: string]: ExternalProductControlOption[] };
export type SingleOptions = { [key: string]: string };
export type DesignOptionGroup = {
  name: string;
  key: string;
  value: VariationGroup[];
};

export type Configuration = {
  disableLinkFeature?: boolean;
  singleSelection?: boolean;
  forceLayout?: boolean;
  showUnavailableVariants?: boolean;
};

export interface VariantsState {
  product: Product;
  replaceControlKeys: string[];
  configuration: Configuration;
  isLoaded: boolean;
  isVariantFlow: boolean;
  selectedPageCount: number;

  selectedGroupKey?: string;
  variationGroups: VariationGroup[];
  selectedMultiOptions: MultiOptions;
  selectedExternalOptions: ExternalOptions;
  selectedSingleOptions: SingleOptions;

  designTemplates: { [designKey: string]: DesignData };
  spreadImages: {
    [groupKey: string]: {
      spreadBackgrounds: SpreadGroundImage[];
      spreadForegrounds: SpreadGroundImage[];
    };
  };
  layoutPerProductUids: { [productUid: string]: string };

  // temporary
  existingVariants: ExistingVariant[];
  groupedSpreadsPerProductUids: { [productUid: string]: SpreadGroup[] };
  // TODO think about merging all ...PerProductUids into 1 object
  resizableElementPerProductUids?: { [productUid: string]: ResizableElement };
  designOptionsEnabled: boolean;
  eCommerceProduct: ECommerceProduct | undefined;
  selectedDesignOptionValue?: string;
  designOptionsControl?: DesignOptionControl[];
}

export interface ResizableElement {
  fullSizeFrameLabelId?: string;
  scaledFrameLabelId?: string;
  fullSizeFrameId: string;
  scaledFrameId: string;
}
