import { GridDate } from '@gelatoas/design-editor-calendar';
import i18n from 'i18next';

export const getYearDatesFrame = (gridDate?: GridDate) => {
  const startDate = new Date(gridDate?.year || new Date().getFullYear() + 1, gridDate?.month || 0, 1);
  const endDate = new Date(startDate.getFullYear() + 1, gridDate?.month || 0, 1);
  endDate.setDate(endDate.getDate() - 1);
  return { startDate, endDate };
};

export const isWithinInterval = (date: Date, start: Date, end: Date) => {
  const originalTime = date.getTime();
  return originalTime <= end.getTime() && originalTime >= start.getTime();
};

export const getFormatedDate = (date: Date) => {
  return date.toLocaleDateString(i18n.language, { day: 'numeric', month: 'short' });
};
